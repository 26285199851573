/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../helpers';
import { changePassword } from '../../../../api/usersApi';
import bcrypt from 'bcryptjs';

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const ChangePassword = ({ show, handleClose, recordId }: Props) => {
  const [idUser, setIDUser] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Effetto per sincronizzare idUser con recordId
  useEffect(() => {
    setIDUser(recordId);
  }, [recordId]); // Esegui quando recordId cambia

  const handleEditUser = async (event: any) => {
    event.preventDefault();
    try {
      // Crittografa la password
      const hashedPassword = await bcrypt.hash(password, 10);

      const userData = {
        ID_User: idUser,
        Password: hashedPassword,
      };

      const response = await changePassword(userData);
      handleClose();

      if (response.status === 200) {
        setModalMessage(response.data);
      }
      setShowConfirmModal(true);
    } catch (error: any) {
      if (error.response) {
        setModalMessage(error.response.data);
      } else if (error.request) {
        setModalMessage("Errore di connessione al server.");
      } else {
        setModalMessage("Errore sconosciuto.");
      }
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
      <Modal
        id="kt_modal_update_delivery_effective"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={show}
        onHide={handleClose}
      >
        <div className="modal-header">
          <h2>Cambio password</h2>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>
        <div className="modal-body">
          <form onSubmit={handleEditUser}>
            <div className='fv-row mb-10'>
              <label htmlFor='password' className='form-label required'>
                Nuova password
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                />
                <span
                  className="input-group-text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <KTSVG 
                    className="svg-icon-2" 
                    path={showPassword 
                      ? '/media/icons/duotune/others/eye-regular.svg' 
                      : '/media/icons/duotune/others/eye-slash-regular.svg'
                    }
                  />
                </span>
              </div>
            </div>
            <button type='submit' className='btn btn-lg btn-light-primary me-3'>Invio</button>
          </form>
        </div>
      </Modal>
      <Modal
        id="kt_modal_confirm_assign"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={showConfirmModal}
        onHide={closeConfirmModal}
      >
        <div className="modal-header">
          <h2>Cambio password</h2>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>
        <div className="modal-body">
          <p>{modalMessage}</p>
        </div>
        <div className="modal-footer">
          <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
        </div>
      </Modal>
    </>,
    modalsRoot
  );
};

export { ChangePassword };