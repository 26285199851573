/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {deleteUser} from '../../../../api/usersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: string;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteUser = ({ show, handleClose, recordId }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [modalMessage, setModalMessage] = useState("");
  
  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleDeleteUser = async () => {
    if (selectedOption === 'Si') {
      try {
        const response = await deleteUser(recordId);
        handleClose();
        if (response.status === 200) {
          setModalMessage(response.data); 
        }
        setShowConfirmModal(true);
      } catch (error: any) {
        if (error.response) {
          // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
          if (error.response.status === 400) {
            setModalMessage(error.response.data);
          } else {
            setModalMessage(error.response.data);
          }
        } else if (error.request) {
          // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
          setModalMessage(error.response.data);
        } else {
          // Qualcosa è andato storto nella creazione della richiesta
          setModalMessage(error.response.data);
        }
        setShowConfirmModal(true);
      }
    } else if (selectedOption === 'No') {
      handleClose();
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_order_delivered"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Elimina utente</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
      <label className='d-flex align-items-center form-label mb-5 required'>
        Sei sicuro di voler eliminare questo utente dal sistema?
        </label>
          <div className='row mb-2' data-kt-buttons='true'>    
          <div className='col'>
                <input 
                    type="radio" 
                    id="Si" 
                    name="deleteUser" 
                    value="Si" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Si" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Si</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="No" 
                    name="deleteUser" 
                    value="No" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="No" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>No</span>
                </label>
              </div>
          </div>
        <br></br><br></br>
        <button className='btn btn-lg btn-light-primary me-3' onClick={handleDeleteUser}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Elimina utente</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>{modalMessage}</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { DeleteUser };