import axios from '../config/axiosConfig';

/* Creazione nuovo utente */
export const createUser = async (userData) => {
  try {
    const response = await axios.post('/api/users/create', userData);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating users:', error);
    throw error;
  }
};

/* Elenco degli utenti che usciranno in futuro */
export const listUsers = async () => {
  try {
    const response = await axios.post('/api/users/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching users: ', error);
    throw error;
  }
};

/* Elenco dei ruoli di un utente */
export const listRoles = async () => {
  try {
    const response = await axios.post('/api/users/listRoles');
    return response.data;
  } catch (error) {
    console.error('Error fetching roles: ', error);
    throw error;
  }
};

/* Dettagli utente per modificarlo */
export const userDetailsToEdit = async (id) => {
  try {
    const response = await axios.get(`/api/users/processing/detailsToEdit/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching details user to edit:', error);
    throw error;
  }
};

/* Modifica le info di un utente */
export const editUser = async (userData) => {
  try {
    const response = await axios.put('/api/users/edit', { userData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error editing user:', error);
    throw error;
  }
};

/* Modifica password utente */
export const changePassword = async (userData) => {
  try {
    const response = await axios.put('/api/users/changePassword', { userData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error changing user\'s password:', error);
    throw error;
  }
};

/* Elimina utente */
export const deleteUser = async (id) => {
  try {
    const response = await axios.put('/api/users/delete', { id });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error deleting user:', error);
    throw error;
  }
};