import React, { FC, useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext, FormikValues } from 'formik';
import Select from 'react-select';
import { listGoggleModels, listGoogles } from '../../../../../api/ordersApi';
import { verificaCorrispondenzaSeriali } from '../../../../../utils/utils';

type OptionType = {
  value: string;
  label: string;
};

type Step2Props = {
  orderId: number;
  assignedGoggles: OptionType[];
  setAssignedGoggles: (goggles: OptionType[]) => void;
  onValidityChange?: (isValid: boolean) => void;
  selectedGoggleModelQuantities: { [key: number]: number };
  setSelectedGoggleModelQuantities: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
};

const Step2: FC<Step2Props> = ({ orderId, assignedGoggles, setAssignedGoggles, onValidityChange, selectedGoggleModelQuantities, setSelectedGoggleModelQuantities  }) => {
  const [goggleModelList, setGoggleModelList] = useState<any[]>([]);
  const [selectedGoggleModelIds, setSelectedGoggleModelIds] = useState<number[]>([]);

  const [availableGoggles, setAvailableGoggles] = useState<OptionType[]>([]);
  const [selectedGoggleIds, setSelectedGoggleIds] = useState<number[]>([]);

  // Usa Formik per accedere al valore del campo "Quantita_visori"
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  useEffect(() => {
    const fetchModelliVisori = async () => {
      try {
        const response = await listGoggleModels();
        setGoggleModelList(response);

        // Creiamo qui la mappatura dei nomi dei modelli ai loro ID
        const modelNameToId = response.reduce((acc: {[key: string]: number}, modello: {Modello: string, ID_Visore: number}) => {
          acc[modello.Modello] = modello.ID_Visore;
          return acc;
        }, {} as {[key: string]: number});
  
        const modelloConteggioParts = values.ModelliConteggio.split(","); // ["1x Quest 2", "2x Quest 3"]
        let totalQuantity = 0;
  
        modelloConteggioParts.forEach((part: string) => {
          const [quantita, ...modelloParts] = part.trim().split(" ");
          const quantitaVisori = parseInt(quantita.replace('x', ''), 10);
          totalQuantity += quantitaVisori;
          const modello = modelloParts.join(' ');
  
          const modelloId = modelNameToId[modello];
  
          if (modelloId !== undefined) {
            setSelectedGoggleModelQuantities(prev => {
              // Definisci il tipo di prev come { [key: number]: number }
              const newQuantities: {[key: number]: number} = {...prev};
              newQuantities[modelloId] = (prev[modelloId] || 0) + quantitaVisori;
              return newQuantities;
            });
            setSelectedGoggleModelIds(prev => {
              const newIds = Array(quantitaVisori).fill(modelloId);
              return [...prev, ...newIds];
            });
          }
        });

      } catch (error) {
        console.error('Errore nel recupero dei modelli di visore:', error);
      }
    };
    fetchModelliVisori();
  }, [values.Numeri_seriali_visori_attivi]);

  useEffect(() => {
    const fetchVisoriAssegnabili = async () => {
      try {
          const response = await listGoogles(orderId);
          console.log("visori assegnabili", response)
          const numeriSerialiAssegnati = values.Numeri_seriali_visori_attivi.split(",");

          let visoriDisponibili = response.filter(
              (goggle: any) => !numeriSerialiAssegnati.includes(goggle.Seriale_visore)
          );

          const visoriDisponibiliOptions = visoriDisponibili.map((goggle: any) => ({
              value: goggle.Seriale_visore,
              label: goggle.Seriale_visore
          }));

          const opzioniVisoriAssegnati = numeriSerialiAssegnati.map((seriale: string) => ({
              value: seriale,
              label: seriale
          }));

          setAvailableGoggles([...visoriDisponibiliOptions, ...opzioniVisoriAssegnati]);
          setAssignedGoggles(opzioniVisoriAssegnati);
      } catch (error) {
          console.error('Errore nel recupero dei visori in stock assegnabili:', error);
      }
    };
    fetchVisoriAssegnabili();

  }, [values.Numeri_seriali_visori_attivi, orderId]);

  /* Funzione gestione cambio seriali */
  const handleGoggleSelectionChange = (selectedOptions: any) => {

    // Aggiorna semplicemente i visori assegnati senza verificare la corrispondenza qui
    setAssignedGoggles(selectedOptions);
    const updatedSelectedGoggleIds = selectedOptions.map((option: any) => option.value);
    setSelectedGoggleIds(updatedSelectedGoggleIds);

    // Aggiorna il valore nel form di Formik se necessario
    setFieldValue('ID_Visori', updatedSelectedGoggleIds.join(','));

  };

  /* Funzione incremento */
  const incrementModel = (modelId: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedGoggleModelQuantities(prev => {
      const updatedQuantities = {
        ...prev,
        [modelId]: (prev[modelId] || 0) + 1
      };

      //verificaCorrispondenzaSeriali(updatedQuantities);
      return updatedQuantities;
    });
  };

  /* Funzione decremento */
  const decrementModel = (modelId: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedGoggleModelQuantities(prev => {
      if (prev[modelId] && prev[modelId] > 0) {
        const updatedQuantities = {
          ...prev,
          [modelId]: prev[modelId] - 1
        };

        //verificaCorrispondenzaSeriali(updatedQuantities);
        return updatedQuantities;
      }
      return prev;
    });
  };

  useEffect(() => {
    const isValid = verificaCorrispondenzaSeriali(assignedGoggles, selectedGoggleModelQuantities, goggleModelList);
    if (onValidityChange) {
      onValidityChange(isValid);
    }
  }, [assignedGoggles, selectedGoggleModelQuantities, goggleModelList]);

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Prodotti</h2>
        <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
      </div>

      {/* Inizio modello visore */}
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-5 required'>
          Modello visore
        </label>

        <div>
          {goggleModelList.map((modello) => (
            <div key={modello.ID_Visore} className="modello-row">
              <span>{modello.Modello}</span>
              <button type="button" className="btn btn-icon btn-active-color-gray-700" onClick={(e) => decrementModel(modello.ID_Visore, e)}>-</button>
              <span>{selectedGoggleModelQuantities[modello.ID_Visore] || 0}</span>
              <button type="button" className="btn btn-icon btn-active-color-gray-700" onClick={(e) => incrementModel(modello.ID_Visore, e)}>+</button>
            </div>
          ))}
        </div>

        {/*<div className='form-text'>
            Visori totali selezionati: {Object.values(selectedGoggleModelQuantities).reduce((a, b) => a + b, 0)}
        </div>*/}
      </div>
      {/* Fine modello visore */}

      {/* Inizio visori assegnati */}
      <Select
        isMulti
        className='form-select-solid form-select-lg fw-bold'
        value={assignedGoggles}
        placeholder="Assegna visori"
        options={availableGoggles}
        onChange={handleGoggleSelectionChange}
      />
      {/* Fine visori assegnati */}

    </div>
  );
};

export { Step2 };