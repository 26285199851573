import * as Yup from 'yup'

export interface ICreateUser {
    Nome: string
    Cognome: string
    Email: string
    Password: string
    COD_Role: number | null
}

export const createUserSchemas = Yup.object().shape({
    Nome: Yup.string().required('Il nome è obbligatorio').label('Nome'),
    Cognome: Yup.string().required('Il cognome è obbligatorio').label('Cognome'),
    Email: Yup.string().email('Email non valida').required('Email obbligatoria').label('Email'),
    Password: Yup.string().min(8, 'La password deve essere di almeno 8 caratteri').required('Password obbligatoria').label('Password'),
    COD_Role: Yup.number().nullable().required('Il ruolo è obbligatorio').label('COD_Role'),
  });

export const inits: ICreateUser = {
    Nome: '',
    Cognome: '',
    Email: '',
    Password: '',
    COD_Role: null,
}
